<template>
  <div class="appointment-info">
    <div class="tip flex-space-between" v-if="showTip">
      <span>提示：只能次日起至5个工作日内，同一天内同一个人只能预约一次。若遇特殊情况，登记中心工作人员会及时打电话联系。</span>
      <i class="el-icon-close" @click="showTip = false"></i>
    </div>
    <div class="info-wrap box-shadow-default">
      <el-form ref="form" label-width="140px" style="display: flow-root;" :model="formData" :rules="rules">
        <div class="header-tip">预约人信息</div>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="申请人姓名" prop="applicantName">
              <el-input v-model="formData.applicantName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="申请人电话" prop="applicantPhone">
              <el-input v-model="formData.applicantPhone"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="身份证号码" prop="certNo">
              <el-input v-model="formData.certNo"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="22">
            <el-form-item label="详细地址" prop="applicantAddr">
              <el-input v-model="formData.applicantAddr"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="人群类型" prop="tsrqType">
              <el-select v-model="formData.tsrqType">
                <el-option v-for="(item, index) in options" :label="item.label" :value="item.value" :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-collapse v-model="activeNames" v-if="formData.tsrqType">
          <el-collapse-item :title="tsrqList[formData.tsrqType]" name="1">
            <div class="info-upload">
              <el-form>
                <el-form-item v-if="formData.tsrqType" prop="tsrqType">
                  <el-upload
                    :data="uploadParams"
                    name="file"
                    accept="jpg,png"
                    :action="submitFileUrl"
                    list-type="picture-card"
                    :before-upload="beforeUpload"
                    :on-success="uploadSuccess"
                    :on-remove="removeFile"
                    :on-preview="handlePictureCardPreview"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt="" />
                  </el-dialog>
                </el-form-item>
              </el-form>
            </div>
          </el-collapse-item>
        </el-collapse>
        <div class="header-tip">预约网点</div>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="预约网点" prop="officeHall">
              <el-input disabled v-model="formData.officeHall"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="网点地址" prop="officeHallAddr">
              <el-input disabled v-model="formData.officeHallAddr"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="网点电话">
              <el-input disabled v-model="formData.officeHallPhone"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="header-tip">预约信息</div>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="预约日期" prop="appointmentTime">
              <el-select v-model="formData.appointmentTime">
                <el-option v-for="(item, index) in appointmentDate" :key="index" :value="item" :label="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="时间段" prop="appointmentPeriod">
              <el-select v-model="formData.appointmentPeriod">
                <el-option label="上午" value="上午"> </el-option>
                <el-option label="下午" value="下午"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="预约事项" prop="reservations">
              <el-select v-model="formData.reservations" @change="matterChange">
                <el-option
                  v-for="(item, index) in appointmentMatter"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="hasPermission('IEBDC:TXSQB:CQZH')">
          <el-col :span="11">
            <el-button @click="add" type="primary">添加不动产权证号</el-button>
          </el-col>
        </el-row>
        <div v-for="(item, index) in formData.yyzh" :key="index">
          <certificate-template
            v-if="item !== 'hidden'"
            v-model="formData.yyzh[index]"
            @deleteRow="deleteRow(index)"
            :disabledBtn="disabledBtn"
            :tempalteList="template"
            :flexWidth="11"
            :deleteBtn="hasPermission('IEBDC:TXSQB:CQZH')"
          >
          </certificate-template>
        </div>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="手机号" prop="applicantPhone">
              <el-input v-model="formData.applicantPhone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="手机验证" prop="certCode" v-if="hasPermission('IEBDC:GNXS:DXYZ')">
              <phone-code
                v-model="formData.certCode"
                :phone="formData.applicantPhone"
                :disabled="getCodeBtnDisabled"
              ></phone-code>
            </el-form-item>
            <el-form-item label="验证码" prop="code" v-else>
              <div class="flex">
                <div style="width: 35%">
                  <el-input placeholder="验证码" v-model="formData.code"></el-input>
                </div>
                <div class="pointer code">
                  <div class="pointer code" @click="refreshCode">
                    <s-identify :identifyCode="identifyCode"></s-identify>
                  </div>
                </div>
                <div @click="refreshCode" class="pointer link" style="line-height: 40px">
                  换一张
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-show="showMatter">
          <el-col :span="22">
            <matterInfo ref="matter" />
          </el-col>
        </el-row>
        <div class="text-center margin-large">
          <el-button type="primary" @click="last">上一步</el-button>
          <el-button type="primary" @click="submit">提交</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { REST_SERVICE } from 'iebdc/config/index';
import { validateName, validateZjh } from 'iebdc/utils/validate';
import { delFileById } from 'iebdc/api/register';
import matterInfo from '../component/matter-info';
import { localDataUser } from '@iebdc/utils/local-data';
import hasPermission from 'iebdc/mixin/hasPermission';
import { zxTemplate } from '@public/zsMould';
import { getYysx, saveAppointment } from 'iebdc/api/visiting-service';
import { bsdtDetail, getSystemTime, getYyysl } from 'iebdc/api/service-appointment';
import { mapGetters } from 'vuex';
import SIdentify from '@/pages/iebdc/views/login/component/identify';

export default {
  name: 'appointment-info',
  mixins: [hasPermission],
  components: {
    matterInfo,
    SIdentify
  },
  props: {
    userData: {
      type: Object,
      required: true
    }
  },
  data() {
    const validatePhone = async (rule, value, callback) => {
      const re = /^((0\d{2,3}-\d{7,8})|((13[0-9])|(14[5,7,9])|(15[^4])|(18[0-9])|(17[0,1,3,5,6,7,8]))\d{8})$/g;
      if (!value) {
        this.getCodeBtnDisabled = true;
        return callback(new Error('请输入电话号码'));
      }
      if (!re.test(value)) {
        this.getCodeBtnDisabled = true;
        return callback(new Error('请输入合法的电话号码'));
      }
      this.getCodeBtnDisabled = false;
      callback();
    };
    // 验证码自定义验证规则
    const validateVerifycode = (rule, value, callback) => {
      if (this.noInputCode) {
        let newVal = value.toLowerCase();
        let identifyStr = this.identifyCode.toLowerCase();
        if (newVal === '') {
          callback(new Error('请输入验证码'));
        } else if (newVal !== identifyStr) {
          callback(new Error('验证码不正确!'));
        } else {
          callback();
        }
      } else {
        callback(new Error('验证码不正确!'));
      }
    };
    this.rules = {
      applicantName: [{ required: true, validator: validateName, trigger: 'change' }],
      certNo: [{ required: true, validator: validateZjh(), trigger: 'change' }],
      applicantAddr: [{ required: true, message: '请填写详细地址', trigger: 'change' }],
      tsrqType: [{ required: true, message: '请选择人群类型', trigger: 'change' }],
      appointmentTime: [{ required: true, message: '请选择预约日期', trigger: 'change' }],
      appointmentPeriod: [{ required: true, message: '请选择预约时间', trigger: 'change' }],
      reservations: [{ required: true, message: '请选择事项', trigger: 'change' }],
      applicantPhone: [{ required: true, validator: validatePhone, trigger: 'change' }],
      certCode: [{ required: true, message: '请填写验证码', trigger: 'change' }],
      code: [{ required: true, validator: validateVerifycode, trigger: 'change' }]
    };
    this.appointmentMatterProps = {
      label: 'name',
      value: 'name'
    };
    return {
      activeNames: ['1'],
      showTip: true, //是否展示提示信息
      dialogVisible: false,
      userInfo: {},
      fileList: [], // 上传的文件列表
      uploadParams: {}, // 上传文件额外带的参数
      dialogImageUrl: '',
      noInputCode: 0, //解决直接提交，图片验证码不提示并且控制台报错问题
      identifyCodes: '1234567890ABCDEFGHIGKLMNoPQRSTUVWXYZ',
      identifyCode: '',
      options: [
        {
          label: '老年人（70周岁以上）',
          value: '1'
        },
        {
          label: '重大疾病患者',
          value: '2'
        },
        {
          label: '残障人士',
          value: '3'
        },
        {
          label: '其他出行不便人群',
          value: '4'
        }
      ],
      submitFileUrl: REST_SERVICE.files.mulFileSave, // 上传文件路径
      tsrqList: {
        '1': '身份证正反面',
        '2': '医院出具的相关证明',
        '3': '残疾证',
        '4': '出行不便相关证明'
      },
      template: zxTemplate,
      getCodeBtnDisabled: true,
      showMatter: false,
      formData: {
        applicantName: '',
        certNo: '',
        applicantPhone: '',
        yyzh: ['']
      },
      appointmentDate: [], // 预约日期
      cacheYyyslData: {}, // 保存数据
      appointmentMatter: [], // 预约事项
      appointmentBranch: [] // 预约网点
    };
  },
  computed: {
    ...mapGetters(['selectedBsdtCode', 'selectedOrganizationsCode', 'selectedOrganizationsTitle', 'selectedBsdtTitle']),
    disabledBtn() {
      const yyzh = this.formData.yyzh.filter((item) => item !== 'hidden');
      return yyzh.length <= 1;
    }
  },
  watch: {
    selectedBsdtTitle() {
      this.init();
    },
    //解决直接提交，图片验证码不提示并且控制台报错问题
    'formData.code': function() {
      this.noInputCode++;
    }
  },
  mounted() {
    // console.log(this.userData,'预约人信息');
    this.init();
  },
  methods: {
    init() {
      this.getUserInfo();
      this.getNewVerCode();
      this.getBsdtDetail();
      this.getYysx();
      this.getYyslAndYysj();
    },
    getUserInfo() {
      this.copyUserData = JSON.parse(JSON.stringify(localDataUser.get()));
      // console.log(this.copyUserData, '用户基本信息');
      //初始化用户信息
      if (this.copyUserData.userType === 'gr') {
        this.formData.applicantName = this.copyUserData.realName;
        this.formData.certNo = this.copyUserData.zjh;
        this.formData.applicantPhone = this.copyUserData.tel;
        this.formData.userId = this.copyUserData.id;
        this.formData.documentType = '1';
      } else {
        this.formData.userId = this.copyUserData.id;
      }
    },
    // 新增验证码相关逻辑 start
    getNewVerCode() {
      this.identifyCode = '';
      this.makeCode(this.identifyCodes, 4);
    },
    // 生成随机数
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 切换验证码
    refreshCode() {
      this.identifyCode = '';
      this.makeCode(this.identifyCodes, 4);
    },
    // 生成四位随机验证码
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      }
    },
    // 新增验证码相关逻辑 end
    // 添加不动产权证号
    add() {
      this.formData.yyzh.push('');
    },
    // 删除不动产权证号
    deleteRow(index) {
      const yyzh = [...this.formData.yyzh];
      yyzh[index] = 'hidden';
      this.$set(this.formData, 'yyzh', yyzh);
    },
    last() {
      this.$emit('last', 0);
    },
    // 获取办事大厅地址
    getBsdtDetail() {
      const params = {
        officeCode: this.selectedBsdtCode
      };
      bsdtDetail(params).then((res) => {
        if (res.data && res.success) {
          const { data } = res;
          this.formData.officeHallAddr = data.address;
          this.formData.officeHall = data.address;
          this.formData.officeHallPhone = data.dh;
        }
      });
    },
    // 获取预约事项
    getYysx() {
      const params = {
        djjg: this.selectedOrganizationsCode,
        userId: this.formData.userId
      };
      getYysx(params).then((res) => {
        if (res && res.success) {
          const { data } = res;
          for (let item of data) {
            if (item.sfkyy === 1) {
              this.appointmentMatter.push(item);
            }
          }
        }
      });
    },
    // 获取预约日期
    getYyslAndYysj() {
      // 获取系统时间
      getSystemTime().then((res) => {
        // 获取预约时间
        const params = {
          djjg: this.selectedOrganizationsTitle,
          bsdt: this.selectedBsdtTitle,
          qssj: res.data
        };
        getYyysl(params).then((res) => {
          if (res && res.success) {
            const { data } = res;
            this.appointmentDate = data.map((item) => item.date);
          }
        });
      });
    },
    // 预约事项改变
    matterChange(value) {
      const matter = this.appointmentMatter.find((item) => item.name === value);
      this.formData.guideId = matter.id;
      // 获取事项信息
      this.$refs.matter.showGuide(this.formData.guideId);
      this.showMatter = true;
    },
    // 在这里上传文件
    async beforeUpload(file) {
      // 判断文件是否重复
      const isExist = this.fileList.find((item) => item.fileName === file.name);
      if (isExist) {
        this.$message.warning('上传文件的文件名不可重复！');
        return Promise.reject();
      }
      //文件名命名规范
      let filetypes = ['~', '!', '@', '#', '$', '%', '^', '&', '*', '|', '-', ' '];
      let filename = file.name.substring(0, file.name.lastIndexOf('.'));
      for (let item of filetypes) {
        if (filename.indexOf(item) > 0) {
          item = item === ' ' ? '空格' : item;
          this.$message.warning(`上传文件的文件名不可包含${item}等特殊字符！`);
          return Promise.reject();
        }
      }
      const res = await getSystemTime();
      const systemTime = res.data;
      this.uploadParams = {
        dir: `smyy/${this.formData.applicantPhone}/${systemTime}`,
        recover: true
      };
    },
    // 文件上传成功
    uploadSuccess(res) {
      if (res && res.success) {
        const { data } = res;
        this.fileList.push({
          id: data[0].info.id,
          fileName: data[0].info.fileName,
          url: data[0].info.filePathUrl,
          type: data[0].info.fileType
        });
      }
    },
    // 删除文件
    removeFile({ name }) {
      // 在上传文件列表里找到改文件
      let index;
      const file = this.fileList.find((item, i) => {
        index = i;
        return item.fileName === name;
      });
      delFileById({ id: file.id }).then((res) => {
        if (res && res.success) {
          this.fileList.splice(index, 1);
        }
      });
    },
    // 查看图片
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 提交表单
    submit() {
      // this.saveAppointment();
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (!this.fileList.length) {
            this.$message({
              message: `请上传${this.tsrqList[this.formData.tsrqType]}相关证件照片证明！`,
              type: 'error'
            });
          } else {
            this.saveAppointment();
          }
        }
      });
    },
    // 预约
    saveAppointment() {
      // 上传文件的id
      this.formData.proofFileId = this.fileList.map((item) => item.id).join(',');
      // 处理证书模板
      // 1.过滤掉hidden数据
      let yyzh = this.formData.yyzh.filter((item) => item !== 'hidden');
      yyzh = yyzh.length > 1 ? yyzh : yyzh[0];
      let formData = {};
      if (this.formData.code) {
        delete this.formData.code;
        delete this.formData.authCode; //不串authCode接口也报错
      }
      for (const key in this.formData) {
        if (key !== 'yyzh') {
          formData[key] = this.formData[key];
        }
      }
      const params = {
        ...formData,
        immovablePropertyNo: yyzh,
        // ...this.userData,
        orgName: this.selectedOrganizationsTitle,
        orgCode: this.selectedOrganizationsCode,
        officeHall: this.selectedBsdtTitle,
        officeCode: this.selectedBsdtCode,
        sfyddbl: 0
      };
      saveAppointment(params).then((res) => {
        if (res && res.success) {
          const appointMentData = {
            ...params
          };
          this.$emit('next', 2);
          this.$emit('sendData', appointMentData);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'src/styles/common-variables';
@import 'src/styles/public';
.appointment-info {
  /deep/ .el-collapse {
    border-top: 0;
    border-bottom: 0;
    font-size: 14px;
    color: #333333;
    margin-bottom: 15px;
    .el-collapse-item__header {
      font-size: 14px;
      background-color: #f2f6fc;
      padding: 15px 20px;
      height: 44.6px;
      line-height: 44.6px;
      font-weight: bold;
    }
    .el-collapse-item__wrap {
      border-bottom: 0;
    }
    .el-collapse-item__content {
      padding-bottom: 0;
    }
  }
  .header-tip {
    background-color: #f2f6fc;
    padding: 15px 20px;
    font-size: 14px;
    color: #333333;
    margin-bottom: 15px;
    font-weight: bold;
  }
  .tip {
    margin: 20px 0;
    padding: 15px 20px 15px 20px;
    background-color: #fff3dd;
    color: #e6a23c;
    font-size: 13px;
    border-radius: 8px;
  }
  .info-wrap {
    background-color: #ffffff;
    margin-top: 20px;
    /deep/ .el-select {
      width: 100%;
    }
    .el-row {
      margin-left: 10px !important;
    }
    .info-upload {
      margin-left: 40px;
      margin-top: 33px;
    }
    .flex {
      justify-content: space-between;
    }
    .code {
      height: 40px;
      img {
        height: 100%;
      }
    }
  }
}
</style>
