<template>
  <div class="read-instructions box-shadow-default">
    <h2 class="text-center">{{ systemName }}上门服务预约须知及协议</h2>
    <p>
      欢迎使用{{ systemName }}。<span class="red-font"
        >为方便特殊群体申请不动产登记，现开通网上预约上门服务功能。申请人在申请前须仔细阅读本须知。</span
      >
    </p>
    <p>
      一、上门服务预约采取实名制，申请人必须是办理不动产登记业务的申请人或委托代理人。
    </p>
    <p>
      二、上门服务预约的时间为次日起至第5个工作日内（以具体开放时间为准），不能预约当天号源，节假日和休息日不接受预约。
    </p>
    <p>三、申请人在预约有效期内只能预约一次，不能重复预约。</p>
    <p>四、预约成功后，如不能按预约时间到达办理，请予预约日前一天取消预约。</p>
    <p>
      <span class="red-font">五、为防止恶意预约，系统已建立黑名单制度，如：预约后多次取消、未在约定时间内来现场办理等情况。</span>
    </p>
    <p><span class="red-font">六、一个预约号只能办理一宗登记业务。</span></p>
    <p>
      <span class="red-font"
        >七、预约成功后，申请人须准备好登记所需材料，按照约定时间和地点，经核实确认后，由公证处相关业务员上门办理网上预约相关的业务。</span
      >
    </p>
    <p>
      <span class="red-font"
        >八、特殊群体特指：老、弱、病、残、孕等健康或身体状况亚于健康人的群体。申请时需上传出行不便的相关证明照片，否则将不予上门服务。</span
      >
    </p>
    <p>
      九、业务办理过程中，因申请人要件材料不完整或者业务数据不实等问题导致事项业务无法顺畅办理，敬请申请人及时予以协助配合和谅解。
    </p>
    <p>
      十、对于自然灾害、电力通讯或其他设施故障等原因，致使预约服务延迟或失效的，登记机关不承担任何责任。
      任何单位和个人不得恶意修改、变更预约服务的有关程序和内容，或利用网上预约进行有偿服务，否则由此引起一切损害后果的，须承担相应的法律责任。
    </p>
    <p>十一、本协议的解释权最终归不动产登记机关所有。</p>
    <div class="margin-large text-center">
      <el-checkbox name="type" v-model="check">我已阅读以上须知并同意</el-checkbox>
    </div>
    <div class="text-center margin-large">
      <div class="djsbox">
        <el-button type="primary" :class="timeCount > 0 ? 'pr40' : ''" :disabled="disabled" @click="next">
          下一步
        </el-button>
        <span v-if="timeCount > 0">（{{ timeCount }}）</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'read-instructions',
  data() {
    return {
      check: false,
      timeCount: 10,
      systemName: window.CONFIG.APP_INFO.systemName
    };
  },
  mounted() {
    const timer = setInterval(() => {
      this.timeCount--;
      if (this.timeCount <= 0) {
        clearInterval(timer);
      }
    }, 1000);
  },
  computed: {
    disabled() {
      return !this.check || this.timeCount > 0;
    }
  },
  methods: {
    next() {
      this.$emit('next', 1);
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'src/styles/common-variables';
@import 'src/styles/public';
.read-instructions {
  margin: 20px 0;
  padding: 20px;
  background-color: #ffffff;
  text-indent: 25px;
  font-size: 13px;
  h2 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  p {
    margin: 0 0 10px;
    line-height: 40px;
  }
  .red-font {
    color: red;
  }
  /deep/ .el-checkbox {
    color: #333333;
    font-weight: bold;
    display: inline;
    &:last-of-type {
      margin-left: -40px;
    }
  }
  /deep/ .el-checkbox__label {
    display: inline;
  }
}
  .djsbox{
    display: inline-block;
    width: 122px;
    position: relative;
    /deep/.el-button{
      text-indent: 0!important;
    }
    /deep/.el-button.pr40{
      padding-right: 40px;
    }
    span{
      position: absolute;
      right: 4px;
      top: 0;
      color: #ffffff;
      height: 32px;
      line-height: 32px;
    }
  }
</style>
